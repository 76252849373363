import React from "react";
import axios from 'axios';
import { Helmet } from "react-helmet";
import { useState, useEffect } from 'react';
import banner from '../Images/banner.jpeg'
import profileimg from '../Images/imran-khan.jpg'
import pti1 from '../Images/PTI1.png'
import pti2 from '../Images/PTI2.png'
import logo from '../Images/logo2.png';
import main from '../Images/main-image.png';
import bg from '../Images/bg.jpg';
import './Index.css';
import $ from 'jquery';


export default function Index() {
    $("#myModal").modal('show');
    $('#sltpp').select2({
        width: '100%'
    });

    const [lstpp, setpp] = useState([]);
    const [lstpps, setpps] = useState([]);
    useEffect(() => {
        $(".loader").hide()
        axios.get("https://api.azadirazakar.com/api/Home/GetProvinceDetails")
            .then(response => setpp(response.data))
            .catch(err => {
                //console.log(err);
            })

        axios.get("https://api.azadirazakar.com/api/Home/GetProvinceDetails")
            .then(response => setpps(response.data))
            .catch(err => {
                //console.log(err);
            })
    }, []);


    const chngEng = () => {
        $("#h4").text("Azaadi Razakar Banye");
        $("#en").text("English");
        $("#ur").text("اردو");
        $("#lname").text("Name:").css("float", "left");
        $("#lcnic").text("CNIC:").css("float", "left");
        $("#lpp").html("PP: <small>(Message your ID card number to 8300 to find out your PP)</small>").css("float", "left");
        $("#lbcode").text("Message your Identity Card number to 8300 for block code.").css("text-align", "start").css("float", "left");
        $("#lmobile").text("Mobile Number:").css("float", "left");
        $("#imobile").text("International Mobile Number:").css("float", "left");
        $("#sub").text("Join AzadiRazakar WhatsApp community now");
        $("#txtwht").text("Do You Want to join Whatsapp Group? ");
        $("#lblnk").css("float", "left");
        $("#urdemail").addClass("d-none");
        $("#engemail").removeClass("d-none");
    }

    const chngUrd = () => {
        $("#h4").text("آزادی رضاکار بنیے");
        $("#en").text("English");
        $("#ur").text("اردو");
        $("#lname").text(":نام").css("float", "right");
        $("#lcnic").text(":قومی شناختی کارڈ").css("float", "right");
        $("#lpp").html("<small>(اپنا حلقہ معلوم کرنے کے لیے 8300 پر ابھی  اپنا شناختی کارڈ نمبر میسج کریں)</small> :حلقہ").css("float", "right");
        $("#lbcode").text("بلاک کوڈ کے لیے اپنا شناختی کارڈ نمبر 8300 پر میسج کریں۔").css("text-align", "end").css("float", "right");
        $("#lmobile").text(":موبائل نمبر").css("float", "right");
        $("#imobile").text(":بین الاقوامی موبائل نمبر").css("float", "right");
        $("#sub").text("آزادی رضاکار واٹس ایپ کمیونٹی جوائن کریں");
        $("#txtwht").text(" کیا آپ واٹس ایپ گروپ جوائن کرنا چاہتے ہیں؟");
        $("#lblnk").css("float", "right");
        $("#urdemail").removeClass("d-none");
        $("#engemail").addClass("d-none");
    }

    var opt = "";
    $('#sltpp').on('change', function (e) {
        opt = $("#sltpp option:selected").text();
        if (opt != "Select") {
            // var url = document.location.search + "?";
            // url = url.substring(0, url.indexOf('?') + 1);
            // window.history.pushState({}, "", url + "pp=" + opt.replace(/ /g, ''));
            $("#lblnk").show();
            $("#br").show();
            $("#brr").show();
            $("#lbpp").text($("#sltpp :selected").text());
            $("#whtlink").val("https://www.whatsapplink.com?" + opt.replace(/ /g, ''));
            $(".select2-selection--single").css("border", "");
        }
        else {
            $("#lblnk").hide();
            $("#br").hide();
            $("#brr").hide();
            $(".select2-selection--single").css("border", "3px solid red");
        }
    });

    const chkpp = () => {
        if ($("#chkpp").is(':checked')) {
            $("#whtlink").show();
        }
        else {
            $("#whtlink").hide();
        }
    }

    const validateName = () => {
        var name = $("#name").val();
        if (name == "" || name == null) {
            $("#name").css("border", "3px solid red");
            $("#name").attr("placeholder", "Please enter Name");
        } else {
            $("#name").css("border", "");
        }
    }

    const validateCnic = () => {
        document.getElementById('cnic').addEventListener('input', function (e) {
            var x = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,7})(\d{0,1})/);
            e.target.value = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
        });
        var cnic = $("#cnic").val();
        if (cnic == "" || cnic == null) {
            $("#cnic").css("border", "3px solid red");
        } else {
            if (cnic.length < 15 || cnic.length > 15) {
                $("#cnic").css("border", "3px solid red");
            } else {
                $("#cnic").css("border", "");
            }
        }
    }

    const validateMobile = () => {
        var locmobile = $("#locmobile").val();
        var intmobile = $("#intmobile").val();
        if ((locmobile == "" || locmobile == null) && (intmobile == "" || intmobile == null)) {
            $("#locmobile").css("border", "3px solid red");
            $("#locmobile").attr("placeholder", "Please enter Mobile");
            $("#intmobile").css("border", "3px solid red");
            $("#intmobile").attr("placeholder", "Please enter Mobile");
        } else {
            if (!$('#chkmobile').is(":checked")) {
                if (locmobile.length < 11 || locmobile.length > 15) {
                    $("#locmobile").css("border", "3px solid red");
                    $("#locmobile").attr("placeholder", "Enter valid Mobile Number");
                } else {
                    $("#locmobile").css("border", "");
                }
            } else {
                // $("#intmobile").css("border", "3px solid red");
                // $("#intmobile").attr("placeholder", "Enter valid Mobile Number");
                $("#intmobile").css("border", "");
            }
        }
    }

    const Submit = () => {
        $(".loader").show()
        var whatAppLink = $("#sltpp").val();
        var pp = $("#sltpp option:selected").text();
        var name = $("#name").val();
        var cnic = $("#cnic").val();
        var locmobile = $("#locmobile").val();
        var intmobile = $("#intmobile").val();

        if (pp == "Select" || name == "" || (locmobile == "" && intmobile == "")) {
            //validatePP();
            $('#sltpp').change();
            validateName();
            //validateCnic();
            validateMobile();
            $(".loader").hide()
            return;
        }
        // else if (cnic.length < 15 || cnic.length > 15) {
        //     validateCnic();
        //     $("#lblcnic").text("Enter valid CNIC");
        //     return;
        // }
        else if (!$('#chkmobile').is(":checked")) {
            if (locmobile.length < 11 || locmobile.length > 15) {
                validateMobile();
                $("#lblmobile").text("Enter valid Mobile");
                $("#lblcnic").text("");
                $(".loader").hide()
                return;
            }
        }
        $("#lblcnic").text("");
        $("#lblmobile").text("");

        var obj = {
            PP: pp,
            Name: name,
            Cnic: cnic,
            Mobile: locmobile,
            InternationalMobile: intmobile
        }

        let json = JSON.stringify(obj);
        fetch('https://api.azadirazakar.com/api/Home/InsertRazakarData', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: json
        })
            .then(response => {
                $(".loader").hide()
                //console.log(response.ok);
                if (response.ok == true) {
                    //alert("Submitted Successfully");
                    window.location.href = whatAppLink;
                    // $.confirm({
                    //     theme: 'material',
                    //     title: "Success",
                    //     content: "Your Message Is Submit Successfully",
                    //     type: 'green',
                    //     typeAnimated: true,
                    //     buttons: {
                    //         tryAgain: {
                    //             text: 'OK',
                    //             btnClass: 'btn-green',
                    //             action: function () {
                    //                 window.location.reload();
                    //             }
                    //         }
                    //     }
                    // });
                }
                else {
                    //alert("Cnic is Already Registered");
                    alert("Submit Failed");
                    // $.confirm({
                    //     theme: 'material',
                    //     title: "Error",
                    //     content: "Failed To Submit Message",
                    //     type: 'red',
                    //     typeAnimated: true,
                    //     buttons: {
                    //         tryAgain: {
                    //             text: 'OK',
                    //             btnClass: 'btn-red',
                    //             action: function () {

                    //             }
                    //         }
                    //     }
                    // });
                    return;
                }
            })
            .catch(error => {
                //console.error(error);
                alert("Submit Failed");
                // $.confirm({
                //     theme: 'material',
                //     title: "Error",
                //     content: error,
                //     type: 'red',
                //     typeAnimated: true,
                //     buttons: {
                //         tryAgain: {
                //             text: 'OK',
                //             btnClass: 'btn-red',
                //             action: function () {

                //             }
                //         }
                //     }
                // });
                return;
            });
    }

    const Cnic = () => {
        document.getElementById('cnic').addEventListener('input', function (e) {
            var x = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,7})(\d{0,1})/);
            e.target.value = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
        });
        // let a = $("#cnic").val();
        // if (a.length == 5) {
        //     $("#cnic").val($("#cnic").val() + '-');
        // } else if (a.length == 7) {
        //     $("#cnic").val($("#cnic").val() + '-');
        // }
    }

    $("input[name='numonly']").on('input', function (e) {
        $(this).val($(this).val().replace(/[^0-9]/g, ''));
    });

    $("#chkmobile").on('change', function () {
        if ($('#chkmobile').is(":checked")) {
            $("#divlocalnumber").attr("hidden", true);
            $("#divinternationalnumber").removeAttr("hidden");
            $("#locmobile").val("");
        } else {
            $("#divinternationalnumber").attr("hidden", true);
            $("#divlocalnumber").removeAttr("hidden");
            $("#intmobile").val("");
        }
    })

    return (
        <>
            <Helmet>
                <title>آزادی رضاکار</title>
            </Helmet>
            <div className="loader"></div>
            <div className="container-fluid d-md-block d-none" id="main-container1">
                <div className="row">
                    <div className="col-md-7 col-6">
                        <a class="navbar-brand d-md-block d-none p-0" href="/" style={{ width: '20%' }}>
                            <img src={logo} id="logo" />
                        </a>

                        <div className="row" style={{ marginTop: '10%' }}>
                            <div className="col-11 ml-auto text-white">
                                <h1 class="text-right font-weight-bold" style={{ fontSize: '17px' }}>آزادی کے علمبردار - آزادی رضاکار</h1>
                                <p className="text-right" style={{ fontSize: '17px' }}>
                                    <b>تعریف اور مقاصد:</b>

                                    حقیقی آزادی کی جدوجہد میں اب فیصلہ کن وقت آن پہنچا ہے۔ چیرمین تحریک انصاف عمران خان نے اس قوم کو باور کرا دیا ہے کہ کہنے کو ہمیں 1947 میں انگریز سامراج سے آزادی ملی لیکن حقیقی آزادی ہمیں آج تک نہی ملی۔

                                    یہ حقیقی آزادی صرف نظام بدلنے اور قانون کی بالادستی قائم کرنے ہی سے ملے گی-

                                    چیرمین عمران خان چاہتے ہیں کہ آپ اس جہاد میں تحریک کا ہراول دستہ بنیں اور آزادی کے علمبردار بنیں۔ آج ہی آزادی رضاکار کے دستے میں شامل ہو جائیں- آزادی کا پیغام اپنی گلی محلوں میں پہنچائیں اور الیکشن کی مہم میں بھرپور حصہ ڈالیں۔

                                    رجسٹر کرنے کے لئیے لنک پر کلک کریں اور آزادی رضاکار بن جائیں۔

                                    پاکستان کو آپ کی ضرورت ہے، کپتان کو آپ کی ضرورت ہے-
                                </p>
                                <br />
                                <div className="text-white">
                                    <h3 className="font-weight-bold" style={{ fontSize: '15px' }}>`ﷲ نے انسان کے ہاتھ میں صرف نیت اور کوشش دی ہے، کامیابی وہ دیتا ہے`</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 text-md-center mx-md-auto col-6 overflow-hidden">
                        <img src={main} id="mainImg" />
                    </div>
                </div>
            </div>

            <div className="container-fluid d-md-none d-block" id="main-container2">
                <div className="row overflow-hidden" id="divRow">
                    <div className="col-md-4 text-md-center mx-md-auto col-6">
                        <img src={main} className="vw-75 vh-100" />
                    </div>
                    <div className="col-8 position-absolute" style={{ marginTop: '15%' }}>
                        <div className="row p-md-4">
                            <div className="col-12 text-white mt-md-0 mt-2">
                                <h1 class="name text-right">آزادی کے علمبردار - آزادی رضاکار</h1>
                                <p className="text-right" style={{ fontSize: '4vw' }}>
                                    <b>تعریف اور مقاصد:</b>

                                    حقیقی آزادی کی جدوجہد میں اب فیصلہ کن وقت آن پہنچا ہے۔ چیرمین تحریک انصاف عمران خان نے اس قوم کو باور کرا دیا ہے کہ کہنے کو ہمیں 1947 میں انگریز سامراج سے آزادی ملی لیکن حقیقی آزادی ہمیں آج تک نہی ملی۔

                                    یہ حقیقی آزادی صرف نظام بدلنے اور قانون کی بالادستی قائم کرنے ہی سے ملے گی-

                                    چیرمین عمران خان چاہتے ہیں کہ آپ اس جہاد میں تحریک کا ہراول دستہ بنیں اور آزادی کے علمبردار بنیں۔ آج ہی آزادی رضاکار کے دستے میں شامل ہو جائیں- آزادی کا پیغام اپنی گلی محلوں میں پہنچائیں اور الیکشن کی مہم میں بھرپور حصہ ڈالیں۔

                                    رجسٹر کرنے کے لئیے لنک پر کلک کریں اور آزادی رضاکار بن جائیں۔

                                    پاکستان کو آپ کی ضرورت ہے، کپتان کو آپ کی ضرورت ہے-

                                </p>
                                <br />
                                <div className="text-white">
                                    <h3 className="" style={{ fontSize: '4vw' }}>`ﷲ نے انسان کے ہاتھ میں صرف نیت اور کوشش دی ہے، کامیابی وہ دیتا ہے`</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="container mt-4">
                <div className="row">
                    {/* <img src={banner} style={{ width: '100%' }} />
                    <div id="carouselExampleControls" class="carousel slide carousel-fade w-100" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img src={pti1} class="d-block w-100" alt="banner1" />
                            </div>
                            <div class="carousel-item">
                                <img src={pti2} class="d-block w-100" alt="banner2" />
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-target="#carouselExampleControls" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-target="#carouselExampleControls" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div> */}
            <br />
            <br />
            <div className="container">
                <div className="row mb-2">
                    <div className="col-12">
                        <h3 className="py-2 text-center text-success font-weight-bold" style={{ textTransform: 'uppercase' }}>All Pakistan Constituencies</h3>
                    </div>
                </div>
                <br />
                <div className="row justify-content-center">
                    {
                        lstpps.length > 0 ?
                            lstpps.map((p, i) => {
                                return (
                                    i % 2 == 0 ?
                                        <div className="col-md-3 col-6 mb-4" key={i}>
                                            <a className="w-100" href={`/become-a-azaadi-razakar?pp=` + p.constituency.replace(/-/g, '')}>
                                                <div className="container" id="card-container2">
                                                    <div className="row border text-white" style={{ height: '120px' }}>
                                                        <div className="col-12 text-center py-3">
                                                            <span className="border px-3 py-2" style={{ borderRadius: '30px', backgroundColor: 'chocolate' }}>{i + 1}</span>
                                                        </div>
                                                        <div className="col-12">
                                                            <h6 className="py-2 text-center font-weight-bold">{p.constituency}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        : <div className="col-md-3 col-6 mb-4" key={i}>
                                            <a className="w-100" href={`/become-a-azaadi-razakar?pp=` + p.constituency.replace(/-/g, '')}>
                                                <div className="container" id="card-container1">
                                                    <div className="row border text-white" style={{ height: '120px' }}>
                                                        <div className="col-12 text-center py-3">
                                                            <span className="border px-3 py-2" style={{ borderRadius: '30px', backgroundColor: 'chocolate' }}>{i + 1}</span>
                                                        </div>
                                                        <div className="col-12">
                                                            <h6 className="py-2 text-center font-weight-bold">{p.constituency}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                )
                            })
                            : ""
                    }
                </div>
            </div >
            <div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content text-white" id="main-container4">
                        <div class="modal-body">
                            {/* <h4 className="font-weight-bold text-center text-white" id="h4">آزادی رضاکار بنیے</h4> */}
                            <img src={logo} style={{ width: '20%' }} />
                            <span className="font-weight-bold text-center text-white float-right" style={{ fontSize: '20px', marginTop: '34px' }} id="h4">آزادی رضاکار بنیے</span>
                            <br /><br />
                            <button className="btn btn-success my-2" id="en" onClick={() => chngEng()}>English</button>&nbsp;
                            <button style={{ float: 'right' }} className="btn btn-danger" id="ur" onClick={() => chngUrd()}>اردو</button>
                            <br />
                            <label className="font-weight-bold" style={{ float: 'right' }} id="lpp"><small>(اپنا حلقہ معلوم کرنے کے لیے 8300 پر ابھی  اپنا شناختی کارڈ نمبر میسج کریں)</small> :حلقہ</label>
                            <select className="form-control select2" id="sltpp">
                                <option value="">Select</option>
                                {
                                    lstpp.length > 0 ?
                                        lstpp.map((p) =>
                                            <option value={p.whatAppLink}>{p.constituency.replace(/-/g, '')}</option>
                                        )
                                        : ""
                                }
                            </select>
                            <br /><br />
                            {/* <label className="font-weight-bold" id="lblnk" style={{ display: 'none', float: 'right' }}>
                            <span id="txtwht">کیا آپ واٹس ایپ گروپ جوائن کرنا چاہتے ہیں؟</span> <input type="checkbox" id="chkpp" onChange={(e) => chkpp(e)} />&nbsp;
                            <br />
                            <span id="lbpp"></span>
                        </label>
                        <input type="text" className="form-control" id="whtlink" style={{ display: 'none' }} />
                        <br id="br" style={{ display: 'none' }} />
                        <br id="brr" style={{ display: 'none' }}  /> */}
                            <label className="font-weight-bold" style={{ float: 'right' }} id="lname">:نام</label>
                            <input type="text" className="form-control" id="name" onKeyUp={() => validateName()} />
                            <br />
                            <label className="font-weight-bold" style={{ float: 'right' }} id="lcnic">:قومی شناختی کارڈ</label>
                            <input type="text" className="form-control" name='numonly' placeholder="_____-_______-_" onKeyUp={() => validateCnic()} id="cnic" />
                            <label id="lblcnic" className="text-warning"></label>
                            <br />
                            {/* <br/>
                        <label className="font-weight-bold" style={{ textAlign: 'end', float: 'right' }} id="lbcode">بلاک کوڈ کے لیے اپنا شناختی کارڈ نمبر 8300 پر میسج کریں</label>
                        <input type="number" className="form-control" /> 
                        <br />*/}
                            <label><input type="checkbox" className="mb-0" id="chkmobile" />&nbsp;Internationl Number</label>
                            <div id="divlocalnumber">
                                <label className="font-weight-bold" style={{ float: 'right' }} id="lmobile">:موبائل نمبر</label>
                                <input type="text" maxLength="15" name='numonly' className="form-control" onKeyUp={() => validateMobile()} id="locmobile" />
                                <label id="lblmobile" className="text-warning"></label>
                            </div>
                            <div id="divinternationalnumber" hidden>
                                <label className="font-weight-bold" style={{ float: 'right' }} id="imobile">:بین الاقوامی موبائل نمبر</label>
                                <input type="text" name='numonly' className="form-control" onKeyUp={() => validateMobile()} id="intmobile" />
                                <label id="lblmobile" className="text-warning"></label>
                            </div>
                            <br />
                            <button className="btn btn-block btn-success" id="sub" onClick={() => Submit()}>آزادی رضاکار واٹس ایپ کمیونٹی جوائن کریں</button>
                            <br />
                            <button className="btn btn-block btn-danger" data-dismiss="modal" id="sub">Cancel</button>
                            <br />
                            <small id="engemail" className="float-left d-none">For any issue contact on this Whatsapp Number : <a href="https://wa.me/+61403854063" style={{ textDecoration: 'underline' }} className="text-white">+61 403 854 063</a></small>
                            <small id="urdemail" className="float-right"><a href="https://wa.me/+61403854063" style={{ textDecoration: 'underline' }} className="text-white">+61 403 854 063</a> : کسی بھی مسئلے کی صورت میں اِس نمبر پر واٹس ایپ کریں</small>
                        </div>
                    </div>
                </div>
            </div>
            <br /><br />
        </>
    )
}