import React from "react"
import logo from '../Images/logo2.png';

export default function Header() {
    return (
        <>
            <div className="d-lg-none d-block">
                {/* <div className="container text-center">
                    <a href="/">
                        <img src={logo} style={{ width: '40%' }} />
                    </a>
                </div> */}
                <nav class="navbar navbar-expand-lg navbar-dark p-0" style={{ background: '#018343' }}>
                    <a href="/" style={{ width: '14%' }}>
                        <img src={logo} style={{ width: '100%' }} />
                    </a>
                    <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse pl-3" id="navbarSupportedContent">
                        <ul class="navbar-nav mx-auto">
                            <li class="nav-item active">
                                <a class="nav-link" href="/">Home <span class="sr-only">(current)</span></a>
                            </li>
                            <li class="nav-item dropdown active">
                                <a class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                                    Province
                                </a>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="/ict/">ICT</a>
                                    <a class="dropdown-item" href="/punjab/">Punjab</a>
                                    <a class="dropdown-item" href="/sindh/">Sindh</a>
                                    <a class="dropdown-item" href="/kpk/">KPK</a>
                                    <a class="dropdown-item" href="/blochistan/">Blochistan</a>
                                </div>
                            </li>
                            <li class="nav-item active">
                                <a class="nav-link" href="/become-a-azaadi-razakar">Form</a>
                            </li>
                            <li class="nav-item active">
                                <a class="nav-link" href="/update-info">Update Info</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>

            <div className="d-lg-block d-none">
                <nav class="navbar navbar-expand-lg navbar-dark" style={{ background: '#018343', height: '70px' }}>
                    {/* <a class="navbar-brand" href="/" style={{ width: '10%' }}>
                        <img src={logo} style={{ width: '100%' }} />
                    </a> */}

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav mx-auto">
                            <li class="nav-item active">
                                <a class="nav-link" href="/">Home <span class="sr-only">(current)</span></a>
                            </li>
                            <li class="nav-item dropdown active">
                                <a class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                                    Province
                                </a>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="/ict/">ICT</a>
                                    <a class="dropdown-item" href="/punjab/">Punjab</a>
                                    <a class="dropdown-item" href="/sindh/">Sindh</a>
                                    <a class="dropdown-item" href="/kpk/">KPK</a>
                                    <a class="dropdown-item" href="/blochistan/">Blochistan</a>
                                </div>
                            </li>
                            {/* <li class="nav-item active">
                                <a class="nav-link" href="/become-a-azaadi-razakar">Form</a>
                            </li>
                            <li class="nav-item active">
                                <a class="nav-link" href="/update-info">Update Info</a>
                            </li> */}
                            <li class="nav-item dropdown active">
                                <a class="nav-link dropdown-toggle" role="button" href="/become-a-azaadi-razakar" aria-expanded="false">
                                    Form
                                </a>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="/update-info">Update Info</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </>
    )
}