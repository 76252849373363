import React from "react";
import axios from 'axios';
import { Helmet } from "react-helmet";
import { useState, useEffect } from 'react';
import $ from 'jquery';
import logo from '../Images/logo2.png';
import './Index.css';
import main from '../Images/main-image2.png';

export default function Form() {
    const [lstpp, setpp] = useState([]);
    useEffect(() => {
        $(".loader").hide()
        axios.get("https://api.azadirazakar.com/api/Home/GetProvinceDetails")
            .then(response => setpp(response.data))
            .catch(err => {
                //console.log(err);
            })
    }, []);
    //console.log(lstpp);

    var a = window.location.search;
    if (a.includes("pp=")) {
        var b = a.replace('?pp=', '').replaceAll('%20', ' ');
        var result = $.grep(lstpp, function (e) { return e.constituency.replace(/-/g, '') == b });
        $(".select2 option:selected").text(b.replace('%20', ' ').replace('%20', ' ').replace('%20', ' ').replace('%20', ' '));
        if (result.length > 0) {
            $(".select2 option:selected").val(result[0].whatAppLink);
        }
    }

    $('#sltpp').select2();

    const chngEng = () => {
        $("#h4").text("Azadi Razakar Banye");
        $("#en").text("English");
        $("#ur").text("اردو");
        $("#lname").text("Name:").css("float", "left");
        $("#lcnic").text("CNIC:").css("float", "left");
        $("#lpp").html("PP: <small>(Message your ID card number to 8300 to find out your PP)</small>").css("float", "left");
        $("#lbcode").text("Message your Identity Card number to 8300 for block code.").css("text-align", "start").css("float", "left");
        $("#lmobile").text("Mobile Number:").css("float", "left");
        $("#imobile").text("International Mobile Number:").css("float", "left");
        $("#sub").text("Join AzadiRazakar WhatsApp community now");
        $("#txtwht").text("Do You Want to join Whatsapp Group? ");
        $("#lblnk").css("float", "left");
        $("#urdemail").addClass("d-none");
        $("#engemail").removeClass("d-none");
    }

    const chngUrd = () => {
        $("#h4").text("آزادی رضاکار بنیے");
        $("#en").text("English");
        $("#ur").text("اردو");
        $("#lname").text(":نام").css("float", "right");
        $("#lcnic").text(":قومی شناختی کارڈ").css("float", "right");
        $("#lpp").html("<small>(اپنا حلقہ معلوم کرنے کے لیے 8300 پر ابھی  اپنا شناختی کارڈ نمبر میسج کریں)</small> :حلقہ").css("float", "right");
        $("#lbcode").text("بلاک کوڈ کے لیے اپنا شناختی کارڈ نمبر 8300 پر میسج کریں۔").css("text-align", "end").css("float", "right");
        $("#lmobile").text(":موبائل نمبر").css("float", "right");
        $("#imobile").text(":بین الاقوامی موبائل نمبر").css("float", "right");
        $("#sub").text("آزادی رضاکار واٹس ایپ کمیونٹی جوائن کریں");
        $("#txtwht").text(" کیا آپ واٹس ایپ گروپ جوائن کرنا چاہتے ہیں؟");
        $("#lblnk").css("float", "right");
        $("#urdemail").removeClass("d-none");
        $("#engemail").addClass("d-none");
    }

    var opt = "";
    $('#sltpp').on('change', function (e) {
        opt = $("#sltpp option:selected").text();
        if (opt != "Select") {
            // var url = document.location.search + "?";
            // url = url.substring(0, url.indexOf('?') + 1);
            // window.history.pushState({}, "", url + "pp=" + opt.replace(/ /g, ''));
            $("#lblnk").show();
            $("#br").show();
            $("#brr").show();
            $("#lbpp").text($("#sltpp :selected").text());
            $("#whtlink").val("https://www.whatsapplink.com?" + opt.replace(/ /g, ''));
            $(".select2-selection--single").css("border", "");
        }
        else {
            $("#lblnk").hide();
            $("#br").hide();
            $("#brr").hide();
            $(".select2-selection--single").css("border", "3px solid red");
        }
    });

    const chkpp = () => {
        if ($("#chkpp").is(':checked')) {
            $("#whtlink").show();
        }
        else {
            $("#whtlink").hide();
        }
    }

    // const validatePP = () => {
    //     var pp = $("#sltpp option:selected").text();
    //     if (pp == "Select") {
    //         $(".select2-selection--single").css("border", "3px solid red");
    //     } else {
    //         $(".select2-selection--single").css("border", "");
    //     }
    // }

    const validateName = () => {
        var name = $("#name").val();
        if (name == "" || name == null) {
            $("#name").css("border", "3px solid red");
            $("#name").attr("placeholder", "Please enter Name");
        } else {
            $("#name").css("border", "");
        }
    }

    const validateCnic = () => {
        document.getElementById('cnic').addEventListener('input', function (e) {
            var x = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,7})(\d{0,1})/);
            e.target.value = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
        });
        var cnic = $("#cnic").val();
        if (cnic == "" || cnic == null) {
            $("#cnic").css("border", "3px solid red");
        } else {
            if (cnic.length < 15 || cnic.length > 15) {
                $("#cnic").css("border", "3px solid red");
            } else {
                $("#cnic").css("border", "");
            }
        }
    }

    const validateMobile = () => {
        var locmobile = $("#locmobile").val();
        var intmobile = $("#intmobile").val();
        if ((locmobile == "" || locmobile == null) && (intmobile == "" || intmobile == null)) {
            $("#locmobile").css("border", "3px solid red");
            $("#locmobile").attr("placeholder", "Please enter Mobile");
            $("#intmobile").css("border", "3px solid red");
            $("#intmobile").attr("placeholder", "Please enter Mobile");
        } else {
            if (!$('#chkmobile').is(":checked")) {
                if (locmobile.length < 11 || locmobile.length > 15) {
                    $("#locmobile").css("border", "3px solid red");
                    $("#locmobile").attr("placeholder", "Enter valid Mobile Number");
                } else {
                    $("#locmobile").css("border", "");
                }
            } else {
                // $("#intmobile").css("border", "3px solid red");
                // $("#intmobile").attr("placeholder", "Enter valid Mobile Number");
                $("#intmobile").css("border", "");
            }
        }
    }

    const Submit = () => {
        $(".loader").show()
        var whatAppLink = $("#sltpp").val();
        var pp = $("#sltpp option:selected").text();
        var name = $("#name").val();
        var cnic = $("#cnic").val();
        var locmobile = $("#locmobile").val();
        var intmobile = $("#intmobile").val();

        if (pp == "Select" || name == "" || (locmobile == "" && intmobile == "")) {
            //validatePP();
            $('#sltpp').change();
            validateName();
            //validateCnic();
            validateMobile();
            $(".loader").hide()
            return;
        }
        // else if (cnic.length < 15 || cnic.length > 15) {
        //     //validateCnic();
        //     $("#lblcnic").text("Enter valid CNIC");
        //     return;
        // } 
        else if (!$('#chkmobile').is(":checked")) {
            if (locmobile.length < 11 || locmobile.length > 15) {
                validateMobile();
                $("#lblmobile").text("Enter valid Mobile");
                $("#lblcnic").text("");
                $(".loader").hide()
                return;
            }
        }
        $("#lblcnic").text("");
        $("#lblmobile").text("");

        var obj = {
            PP: pp,
            Name: name,
            Cnic: cnic,
            Mobile: locmobile,
            InternationalMobile: intmobile
        }

        let json = JSON.stringify(obj);
        fetch('https://api.azadirazakar.com/api/Home/InsertRazakarData', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: json
        })
            .then(response => {
                $(".loader").hide()
                //console.log(response.ok);
                if (response.ok == true) {
                    //alert("Submitted Successfully");
                    window.location.href = whatAppLink;
                    // $.confirm({
                    //     theme: 'material',
                    //     title: "Success",
                    //     content: "Your Message Is Submit Successfully",
                    //     type: 'green',
                    //     typeAnimated: true,
                    //     buttons: {
                    //         tryAgain: {
                    //             text: 'OK',
                    //             btnClass: 'btn-green',
                    //             action: function () {
                    //                 window.location.reload();
                    //             }
                    //         }
                    //     }
                    // });
                }
                else {
                    //alert("Cnic is Already Registered");
                    alert("Submit Failed");
                    // $.confirm({
                    //     theme: 'material',
                    //     title: "Error",
                    //     content: "Failed To Submit Message",
                    //     type: 'red',
                    //     typeAnimated: true,
                    //     buttons: {
                    //         tryAgain: {
                    //             text: 'OK',
                    //             btnClass: 'btn-red',
                    //             action: function () {

                    //             }
                    //         }
                    //     }
                    // });
                    return;
                }
            })
            .catch(error => {
                //console.error(error);
                alert("Submit Failed");
                // $.confirm({
                //     theme: 'material',
                //     title: "Error",
                //     content: error,
                //     type: 'red',
                //     typeAnimated: true,
                //     buttons: {
                //         tryAgain: {
                //             text: 'OK',
                //             btnClass: 'btn-red',
                //             action: function () {

                //             }
                //         }
                //     }
                // });
                return;
            });
    }

    const Cnic = () => {
        document.getElementById('cnic').addEventListener('input', function (e) {
            var x = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,7})(\d{0,1})/);
            e.target.value = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
        });
        // let a = $("#cnic").val();
        // if (a.length == 5) {
        //     $("#cnic").val($("#cnic").val() + '-');
        // } else if (a.length == 7) {
        //     $("#cnic").val($("#cnic").val() + '-');
        // }
    }

    $("input[name='numonly']").on('input', function (e) {
        $(this).val($(this).val().replace(/[^0-9]/g, ''));
    });

    $("#chkmobile").on('change', function () {
        if ($('#chkmobile').is(":checked")) {
            $("#divlocalnumber").attr("hidden", true);
            $("#divinternationalnumber").removeAttr("hidden");
            $("#locmobile").val("");
        } else {
            $("#divinternationalnumber").attr("hidden", true);
            $("#divlocalnumber").removeAttr("hidden");
            $("#intmobile").val("");
        }
    })

    document.querySelectorAll("option").forEach((x) => {
        if (x.innerText.includes("Select")) {
            x.style.display = 'none';
            x.style.visibility = 'none';
        }
    });

    return (
        <>
            <Helmet>
                <title>آزادی رضاکار بنیے</title>
            </Helmet>
            <div className="loader"></div>
            <div className="container-fluid" id="main-container3">
                <div className="row">
                    <div className="col-md-7 col-6">
                        <a class="navbar-brand d-md-block d-none p-0" href="/" style={{ width: '20%' }}>
                            <img src={logo} id="logo" />
                        </a>
                        {/* <div className="row">
                            <nav class="navbar navbar-expand-lg navbar-dark">
                                <a class="navbar-brand" href="/" style={{ width: '30%' }}>
                                    <img src={logo} id="logo" />
                                </a>
                                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul class="navbar-nav" style={{ marginLeft: '30%' }}>
                                        <li class="nav-item active">
                                            <a class="nav-link" href="/">Home <span class="sr-only">(current)</span></a>
                                        </li>
                                        <li class="nav-item dropdown active">
                                            <a class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                                                Province
                                            </a>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" href="/punjab/">Punjab</a>
                                                <a class="dropdown-item" href="/sindh/">Sindh</a>
                                                <a class="dropdown-item" href="/kpk/">KPK</a>
                                                <a class="dropdown-item" href="/blochistan/">Blochistan</a>
                                            </div>
                                        </li>
                                        <li class="nav-item dropdown active">
                                            <a class="nav-link dropdown-toggle" role="button" href="/become-a-azaadi-razakar" aria-expanded="false">
                                                Form
                                            </a>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" href="/update-info">Update Info</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div> */}
                    </div>
                    <div className="col-md-4 text-md-center mx-md-auto col-6 overflow-hidden">
                        <img src={main} id="mainImg" />
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 text-white col-12 bg-white px-5 pb-5" id="main-container4">
                        {/* <h4 className="font-weight-bold text-center text-white" id="h4">آزادی رضاکار بنیے</h4> */}
                        <br/>
                        <img src={logo} style={{ width: '20%' }} />
                        <span className="font-weight-bold text-center text-white float-right" style={{ fontSize: '17px', marginTop: '8%' }} id="h4">آزادی رضاکار بنیے</span>
                        <br /><br />
                        <button className="btn btn-success my-2" id="en" onClick={() => chngEng()}>English</button>&nbsp;
                        <button style={{ float: 'right' }} className="btn btn-danger" id="ur" onClick={() => chngUrd()}>اردو</button>
                        <br />
                        <label className="font-weight-bold" style={{ float: 'right' }} id="lpp"><small>(اپنا حلقہ معلوم کرنے کے لیے 8300 پر ابھی  اپنا شناختی کارڈ نمبر میسج کریں)</small> :حلقہ</label>
                        <select className="form-control select2" id="sltpp">
                            <option value="">Select</option>
                            {
                                lstpp.length > 0 ?
                                    lstpp.map((p) =>
                                        // <option value={p.whatAppLink}>{p.constituency} <span>{p.constituency.replace(/-/g, '').split(' ')[0]}</span></option>
                                        <option value={p.whatAppLink}>{p.constituency.replace(/-/g, '')}</option>
                                        // <option value={p.whatAppLink}>{p.constituency + ' / ' + p.constituency.replace(/-/g, '')}</option>
                                        // <option hidden value={p.whatAppLink}>
                                        //     {p.constituency} {p.constituency.replace(/-/g, '')}
                                        // </option>
                                    )
                                    : ""
                            }
                        </select>
                        <br /><br />
                        {/* <label className="font-weight-bold" id="lblnk" style={{ display: 'none', float: 'right' }}>
                            <span id="txtwht">کیا آپ واٹس ایپ گروپ جوائن کرنا چاہتے ہیں؟</span> <input type="checkbox" id="chkpp" onChange={(e) => chkpp(e)} />&nbsp;
                            <br />
                            <span id="lbpp"></span>
                        </label>
                        <input type="text" className="form-control" id="whtlink" style={{ display: 'none' }} />
                        <br id="br" style={{ display: 'none' }} />
                        <br id="brr" style={{ display: 'none' }}  /> */}
                        <label className="font-weight-bold" style={{ float: 'right' }} id="lname">:نام</label>
                        <input type="text" className="form-control" id="name" onKeyUp={() => validateName()} />
                        <br />
                        <label className="font-weight-bold" style={{ float: 'right' }} id="lcnic">:قومی شناختی کارڈ</label>
                        <input type="text" className="form-control" name='numonly' placeholder="_____-_______-_" onKeyUp={() => validateCnic()} id="cnic" />
                        <label id="lblcnic" className="text-warning"></label>
                        <br />
                        {/* <br/>
                        <label className="font-weight-bold" style={{ textAlign: 'end', float: 'right' }} id="lbcode">بلاک کوڈ کے لیے اپنا شناختی کارڈ نمبر 8300 پر میسج کریں</label>
                        <input type="number" className="form-control" /> 
                        <br />*/}
                        <label><input type="checkbox" className="mb-0" id="chkmobile" />&nbsp;Internationl Number</label>
                        <div id="divlocalnumber">
                            <label className="font-weight-bold" style={{ float: 'right' }} id="lmobile">:موبائل نمبر</label>
                            <input type="text" maxLength="15" name='numonly' className="form-control" onKeyUp={() => validateMobile()} id="locmobile" />
                            <label id="lblmobile" className="text-warning"></label>
                        </div>
                        <div id="divinternationalnumber" hidden>
                            <label className="font-weight-bold" style={{ float: 'right' }} id="imobile">:بین الاقوامی موبائل نمبر</label>
                            <input type="text" name='numonly' className="form-control" onKeyUp={() => validateMobile()} id="intmobile" />
                            <label id="lblmobile" className="text-warning"></label>
                        </div>
                        <br />
                        <button className="btn btn-block btn-success" id="sub" onClick={() => Submit()}>آزادی رضاکار واٹس ایپ کمیونٹی جوائن کریں</button>
                        <br />
                        {/* <small id="engemail" className="float-left d-none">For any issue contact on email: mmazadirazakar@gmail.com</small>
                        <small id="urdemail" className="float-right">mmazadirazakar@gmail.com :کسی بھی مسئلے کے لیے ای میل پر رابطہ کریں</small> */}
                        <small id="engemail" className="float-left d-none">For any issue contact on this Whatsapp Number : <a href="https://wa.me/+61403854063" style={{ textDecoration: 'underline' }} className="text-white">+61 403 854 063</a></small>
                        <small id="urdemail" className="float-right"><a href="https://wa.me/+61403854063" style={{ textDecoration: 'underline' }} className="text-white">+61 403 854 063</a> : کسی بھی مسئلے کی صورت میں اِس نمبر پر واٹس ایپ کریں</small>
                    </div>
                </div>
            </div>
            <br /><br />
        </>
    )
}