import React from "react";
import axios from 'axios';
import { Helmet } from "react-helmet";
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import logo from '../Images/logo2.png';
import './Index.css'
import main from '../Images/main-image2.png';

export default function PP() {
    const parameter = useParams();

    const [lstpp, setpp] = useState([]);
    useEffect(() => {
        axios
            .get("https://api.azadirazakar.com/api/Home/GetPPByProvince?province=" + parameter.parameter)
            .then(response => setpp(response.data))
            .catch(err => {
                //console.log(err);
            })
    }, []);
    //console.log(lstpp);

    let province = ""
    if (parameter.parameter == "punjab") {
        province = "پنجاب"
    } else if (parameter.parameter == "sindh") {
        province = "سندھ"
    } else if (parameter.parameter == "kpk") {
        province = "خیبر پختونخواہ"
    } else if (parameter.parameter == "blochistan") {
        province = "بلوچستان"
    }

    return (
        <>
            <Helmet>
                <title>{province}</title>
            </Helmet>
            <div className="container-fluid" id="main-container3">
                <div className="row">
                    <div className="col-md-7 col-6">
                        <a className="navbar-brand d-md-block d-none p-0" href="/" style={{ width: '20%' }}>
                            <img src={logo} id="logo" />
                        </a>
                        {/* <div className="row">
                            <nav class="navbar navbar-expand-lg navbar-dark">
                                <a class="navbar-brand" href="/" style={{ width: '30%' }}>
                                    <img src={logo} id="logo" />
                                </a>
                                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul class="navbar-nav" style={{ marginLeft: '30%' }}>
                                        <li class="nav-item active">
                                            <a class="nav-link" href="/">Home <span class="sr-only">(current)</span></a>
                                        </li>
                                        <li class="nav-item dropdown active">
                                            <a class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                                                Province
                                            </a>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" href="/punjab/">Punjab</a>
                                                <a class="dropdown-item" href="/sindh/">Sindh</a>
                                                <a class="dropdown-item" href="/kpk/">KPK</a>
                                                <a class="dropdown-item" href="/blochistan/">Blochistan</a>
                                            </div>
                                        </li>
                                        <li class="nav-item dropdown active">
                                            <a class="nav-link dropdown-toggle" role="button" href="/become-a-azaadi-razakar" aria-expanded="false">
                                                Form
                                            </a>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" href="/update-info">Update Info</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div> */}
                    </div>
                    <div className="col-md-4 text-md-center mx-md-auto col-6 overflow-hidden">
                        <img src={main} id="mainImg" />
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div className="container">
                <div className="row mb-2">
                    <div className="col-12">
                        <h3 className="py-2 text-center text-success font-weight-bold" style={{ textTransform: 'uppercase' }}>{parameter.parameter.replace(/\b./g, function (m) { return m.toUpperCase() })} Constituencies</h3>
                    </div>
                </div>
                <br />
                <div className="row justify-content-center">
                    {
                        lstpp.length > 0 ?
                            lstpp.map((p, i) => {
                                return (
                                    parameter.parameter == "punjab" ?
                                        i % 2 == 0 ?
                                            <div className="col-md-3 col-6 mb-4" key={i}>
                                                <a className="w-100" href={`/become-a-azaadi-razakar?pp=` + p.constituency.replace(/-/g, '')}>
                                                    <div className="container" id="card-container2">
                                                        <div className="row border text-white" style={{ height: '120px' }}>
                                                            <div className="col-12 text-center py-3">
                                                                <span className="border px-3 py-2" style={{ borderRadius: '30px', backgroundColor: 'chocolate' }}>{i + 1}</span>
                                                            </div>
                                                            <div className="col-12">
                                                                <h6 className="py-2 text-center font-weight-bold">{p.constituency}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            : <div className="col-md-3 col-6 mb-4" key={i}>
                                                <a className="w-100" href={`/become-a-azaadi-razakar?pp=` + p.constituency.replace(/-/g, '')}>
                                                    <div className="container" id="card-container1">
                                                        <div className="row border text-white" style={{ height: '120px' }}>
                                                            <div className="col-12 text-center py-3">
                                                                <span className="border px-3 py-2" style={{ borderRadius: '30px', backgroundColor: 'chocolate' }}>{i + 1}</span>
                                                            </div>
                                                            <div className="col-12">
                                                                <h6 className="py-2 text-center font-weight-bold">{p.constituency}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        : i % 2 == 0 ?
                                            <div className="col-md-3 col-6 mb-4" key={i}>
                                                <a className="w-100" href={`/become-a-azaadi-razakar?pp=` + p.constituency.replace(/-/g, '')}>
                                                    <div className="container" id="card-container2">
                                                        <div className="row border text-white" style={{ height: '120px' }}>
                                                            <div className="col-12 text-center py-3">
                                                                <span className="border px-3 py-2" style={{ borderRadius: '30px', backgroundColor: 'chocolate' }}>{i + 1}</span>
                                                            </div>
                                                            <div className="col-12">
                                                                <h6 className="py-2 text-center font-weight-bold">{p.constituency}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            : <div className="col-md-3 col-6 mb-4" key={i}>
                                                <a className="w-100" href={`/become-a-azaadi-razakar?pp=` + p.constituency.replace(/-/g, '')}>
                                                    <div className="container" id="card-container1">
                                                        <div className="row border text-white" style={{ height: '120px' }}>
                                                            <div className="col-12 text-center py-3">
                                                                <span className="border px-3 py-2" style={{ borderRadius: '30px', backgroundColor: 'chocolate' }}>{i + 1}</span>
                                                            </div>
                                                            <div className="col-12">
                                                                <h6 className="py-2 text-center font-weight-bold">{p.constituency}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                )
                            })
                            : <div className="loader"></div>
                    }
                </div>
            </div>
            <br />
        </>
    )
}